
import Layout from 'components/Layout';
import {serverApi} from '../Config';
import React from 'react';


function Home({ page }) {
  const { meta } = page;
  return (
    <Layout>
      <Layout.Head title={meta.seo_title} description={meta.search_description} />

      <h1>{page.title}</h1>

      {page.body && (
        <div dangerouslySetInnerHTML={{__html: page.body}} />
      )}
    </Layout>
  )
}

export async function getServerSideProps(ctx) {
  let props = {};

  await serverApi.get(`wagtail/pages/home/`).then(res => {
    props['page'] = res.data;
  }).catch(error => {
    props['page'] = {
      meta: {}
    };
  });

  return { props: props }
}

export default Home;
